<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="grey"
                dark
                class="text-h5"
                dense
            >Inserimento Note
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid >
                <v-row no-gutters class="px-12 mt-8">
                    <v-textarea
                        auto-grow
                        label="Note"
                        outlined
                        v-model="editedRecordNote"
                    />
                </v-row>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn
                    color="orange lighten-2"
                    class="px-4"
                    @click="removeRecordNote"
                    :disabled="(recordNote || '') == ''"
                >  
                    <v-icon left>
                        mdi-close
                    </v-icon>
                    Rimuovi
                </v-btn>
                <v-btn
                    color="green accent-2"
                    class="px-4"
                    :disabled="saveDisabled"
                    @click="saveMethod"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Salva
                </v-btn>
            </v-card-actions>   
        </v-card>  
    </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            editedRecordNote: null,
        }
    },
    props: {
        recordNote: String,
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        cancelMethod: function() {
            this.$emit('close-record-note-edit-dialog');
        },
        saveMethod: function() {
            this.$emit('save-record-note', this.editedRecordNote);
            this.$emit('close-record-note-edit-dialog');
        },
        removeRecordNote: function() {
            this.$emit('save-record-note', null);
            this.$emit('close-record-note-edit-dialog');
        }
    },
    watch: {
        show: function(val){
            if(val)
                this.editedRecordNote = this.recordNote;
        }
    },
    computed: {
        saveDisabled: function() {
            return this.editedRecordNote == this.recordNote;
        }
    }
}
</script>