import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h5",attrs:{"color":"grey","dark":"","dense":""}},[_vm._v("Inserimento Note "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelMethod}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"px-12 mt-8",attrs:{"no-gutters":""}},[_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","outlined":""},model:{value:(_vm.editedRecordNote),callback:function ($$v) {_vm.editedRecordNote=$$v},expression:"editedRecordNote"}})],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"px-4",attrs:{"color":"orange lighten-2","disabled":(_vm.recordNote || '') == ''},on:{"click":_vm.removeRecordNote}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Rimuovi ")],1),_c(VBtn,{staticClass:"px-4",attrs:{"color":"green accent-2","disabled":_vm.saveDisabled},on:{"click":_vm.saveMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Salva ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }